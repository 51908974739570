import { createTypes } from "reduxsauce";
import { history } from "./../../utils";
import { userService } from "./../../services";
import { userActions } from "./user-action";
import { firebase } from "../../utils";

export const appTypes = createTypes(
  `
      SET_INITIALIZED
    `,
  { prefix: "APP/" }
);

const initialize = () => {
  return async dispatch => {
    const authListener = () => {
      const authListenerSubscribe = userService.authListener(
        user => {
          if (user) {
            history.push("/landingpage");
            return (
              dispatch(userActions.user(user)), firebase.getDashboardData()
            );
          }
          authListenerSubscribe();
        },
        error => {
          console.log("error", error);
          authListenerSubscribe();
        }
      );
    };
    authListener();

    dispatch({
      type: appTypes.SET_INITIALIZED,
      initialized: true
    });
  };
};

export const appActions = {
  initialize
};
