import styled from "styled-components";

export const Container = styled.div`
  background: rgb(46, 46, 46);
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 0px 68px;
`;
export const Header = styled.div`
  background: rgb(46, 46, 46);
  display: flex;
  align-items: center;
  margin: 33px 0px 50px;

  @media (max-width: 1340px) {
    margin-bottom: 35px;
  }
`;
export const Content = styled.div`
  background: rgb(46, 46, 46);
  display: flex;
  flex-direction: column;
  flex: 4;
`;

//====================================================
export const Text = styled.span`
  color: white;
  font-size: ${props => `${props.fontSize}px`};
  font-weight: ${props => `${props.fontWeight}`};
  @media (max-width: 1366px) {
    font-size: ${props =>
      props.resize
        ? `calc(${props.fontSize}px - 5px)`
        : props.panel
        ? `calc(${props.fontSize}px - 12px)`
        : null};
  }
  @media (max-width: 1200px) {
    margin-right: ${props => props.margin};
    margin-left: ${props => props.margin};
    font-size: ${props =>
      props.resize
        ? `calc(${props.fontSize}px - 1vw)`
        : props.panel
        ? `calc(${props.fontSize}px - 1.5vw)`
        : null};
  }
`;

export const TextHeader = styled.span`
  color: white;
  font-size: ${props => `${props.fontSize}px`};
  font-weight: ${props => `${props.fontWeight}`};
  @media (max-width: 1200px) {
    font-size: ${props => `calc(${props.fontSize}px - 1vw)`};
  }
`;

export const Icon = styled.img`
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  margin-left: ${props => (props.left ? "auto" : null)};
`;

//====================================================
export const InfoPanel = styled.div`
  background: rgb(67, 67, 67);
  width: 100%;
  height: 92px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding-left: 10px;
  margin-bottom: 35px;
  span {
    padding-left: 10px;
  }
  @media (max-width: 1340px) {
    margin-bottom: 20px;
  }
  @media (max-width: 1366px) {
    width: 50%;
  }
`;

export const Box1 = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Box0 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1366px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const AllEmails = styled.div`
  background: rgb(67, 67, 67);
  height: 157px;
  width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-right: 43px;
  margin: 0px 43px 21px 0px;
  @media (max-width: 1366px) {
    height: 130px;
  }
`;
export const HeaderAllEmails = styled.div`
  width: 275px;
  height: 66px;
  background: #262626;
  display: flex;
  padding-left: 15px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  span {
    margin-left: 10px;
  }
`;
export const NotResolv = styled.div`
  background: rgb(67, 67, 67);
  height: 157px;
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  @media (max-width: 1366px) {
    height: 130px;
  }
`;

export const ContentEmails = styled.div`
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 140px;
`;

export const HeaderNotResolv = styled.div`
  height: 70px;
  background: rgb(38, 38, 38);
  display: flex;
  padding-left: 15px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  span {
    margin-left: 10px;
  }
`;

export const ContentNotResolv = styled.div`
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 140px;
`;

export const Status = styled.div`
  background: rgb(67, 67, 67);
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-right: 43px;
  margin: 0px 0px 35px 0px;
  @media (max-width: 1366px) {
    margin-left: 15px;
    width: calc(100% - 43px);
  }
`;

export const HeaderStatus = styled.div`
  display: flex;
  height: 66px;
  background: #262626;
  padding-left: 15px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  span {
    margin-left: 10px;
  }
`;

export const ContentStatus = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 92px;
`;

export const EmailsData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 2px;
`;
export const EmailsBox = styled.div`
  background: ${props => `${props.color}`};
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  margin: 2px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-height: 100px;
  @media (min-width: 1200px) {
    min-height: 70px;
  }
`;
export const Line = styled.div`
  border: solid 1px ${props => `${props.color}`};
  height: 100%;
`;
