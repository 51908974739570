import styled from "styled-components";

export const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  margin: 0px 2px 5px 0px;
  border-radius: 5px;
  cursor: pointer;
  background: ${props => (props.selected ? "#575757" : null)};
  :hover {
    background: #575757;
  }
`;
export const EmailView = styled.div`
  width: 100px;
  display: flex;
  flex: 2;
  flex-direction: column;
  margin-right: auto;
`;

export const ReadContainer = styled.div`
  padding: auto;
  margin: 0px 10px 0px 0px;
`;

export const ReadEmail = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #3e64c2;
`;

export const Email = styled.span`
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc(6px + 1vmin);
`;
export const Assunto = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: white;
  font-size: calc(8px + 1vmin);
  font-weight: small;
`;
export const Icone = styled.img`
  flex: 1;
  width: ${props => (props.size ? props.size : "22px")};
  height: ${props => (props.size ? props.size : "22px")};
`;
export const NotFound = styled.label`
  width: 100%;
  align-self: center;
  font-style: italic;
  opacity: 0.5;
  color: gray;
  text-align: center;

  .scroll {
    background: #fff;
  }
`;
