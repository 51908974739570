import app from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import data from "./roboMailProductionDatabase.json";
import { store } from "./../store";
import {
  emailsActions,
  dashboardActions,
  subscribeActions
} from "./../store/actions";

const firebaseConfig = {
  apiKey: "AIzaSyCkkyH7flmFLg4hmzuAfuusqTGtc0M90ns",
  authDomain: "robomail-duplicata.firebaseapp.com",
  databaseURL: "https://robomail.firebaseapp.com/",
  projectId: "robomail-duplicata",
  storageBucket: "robomail-duplicata.appspot.com",
  messagingSenderId: "1014978633260",
  appId: "1:1014978633260:web:2e772c86961f28e7497b89",
  measurementId: "G-1W3J331PV6",
  credential: data
};

const firebaseApp = app.initializeApp(firebaseConfig);
export const firestore = firebaseApp.firestore();

const getEmailRealtime = (limit, emailsLength) => {
  limit = limit || 30;

  let state = store.getState();
  let { subscribe } = state.subscribe;
  let tipo = typeof subscribe;
  if (tipo === "function") {
    subscribe();
  }

  if (limit !== emailsLength) {
    return new Promise((resolve, reject) => {
      let subscribe = firestore
        .collection(`emails`)
        .limit(limit)
        .orderBy("dataUltimo", "desc")
        .onSnapshot(async snapshot => {
          let state = store.getState();
          let dados = state.emails.emails.length;
          let emails = state.emails;
          let { subscribeRedux } = state.subscribe;

          {
            !subscribeRedux &&
              store.dispatch(subscribeActions.setSubscribe(subscribe));
          }

          snapshot.docChanges().forEach(change => {
            if (change.type === "added") {
              let encontrou = emails.emails.find(
                email => email.doc_id === change.doc.id
              );
              if (!encontrou) {
                dados++;
                store.dispatch(
                  emailsActions.Emails({
                    doc_id: change.doc.id,
                    ...change.doc.data()
                  })
                );
              }
            }
            if (change.type === "modified") {
              let newEmails = emails.emails.map(email => {
                if (email.doc_id === change.doc.id) {
                  return { doc_id: change.doc.id, ...change.doc.data() };
                }
                return email;
              });
              store.dispatch(emailsActions.Emails(newEmails));
            }
          });
          if (limit !== dados) {
            await getEmailRealtime(dados, limit, subscribe);
          } else {
            resolve(false);
          }
        });
    });
  }
};

const getDashboardData = () => {
  firestore
    .collection("index")
    .doc("dashboard")
    .onSnapshot(snapshot => {
      store.dispatch(dashboardActions.setDashboardData(snapshot.data()));
    });
};

//====================FIM====================
export const firebase = {
  app: firebaseApp,
  getEmailRealtime,
  auth: firebaseApp.auth(),
  getDashboardData
};
