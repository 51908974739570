import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 102px;
`;

export const Input = styled.input`
  width: 236px;
  height: 28px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 11px;
  color: #484848;
  font-size: 14px;
  padding: 5px;
  outline: none;
`;

export const RequiredContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const Required = styled.span`
  color: #3e64c2;
`;

export const SendButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 8px;
`;

export const SendButton = styled.button`
  outline: none;
  display: flex;
  justify-content: center;
  width: 80px;
  height: 30px;
  background-color: #3e64c2;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.05s linear;
  transition: 0.4s;
  margin-bottom: 10px;
  ${props => props.disabled && `opacity: 0.7; cursor: not-allowed;`}
`;

export const Line = styled.div`
  height: 1px;
  border: 1px solid #484848;
  display: flex;
  flex: 1;
`;
