import React, { Component } from "react";
import { ButtonStyle, Text } from "./styled";

export default class Button extends Component {
  render() {
    const { label, selected } = this.props;
    return (
      <ButtonStyle selected={selected} onClick={this.props.handleClick}>
        <Text>{label}</Text>
      </ButtonStyle>
    );
  }
}
