import React, { Component } from "react";
import * as s from "./styled";
import { Button, SearchBar, EmailList, Loader } from "../../components";
import { icons } from "./../../assets/icons/index";
import { connect } from "react-redux";

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentBotao: [],
      contentSearch: [],
      searchFilter: "",
      buttonFilter: "todas"
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  contentFilter = () => {
    var { emails } = this.props.emails;
    const { buttonFilter, searchFilter } = this.state;

    if (buttonFilter !== "todas") {
      let resp = {};
      for (let i in emails) {
        if (emails[i].status === buttonFilter) {
          resp = { ...resp, [emails[i].doc_id]: emails[i] };
        }
      }
      return resp;
    }

    if (searchFilter !== "") {
      let resp = {};
      let parts = searchFilter.split(" ");
      parts.forEach(searchFilter => {
        const regExp = new RegExp(searchFilter, "i");
        for (let i in emails) {
          let parts = [
            emails[i].doc_id,
            emails[i].remetente_email,
            emails[i].assunto,
            emails[i].status
          ];
          for (var j = 0; j <= parts.length; j++) {
            if (regExp.test(parts[j])) {
              resp = { ...resp, [emails[i].doc_id]: emails[i] };
            }
          }
        }
      });
      return resp;
    }

    return emails;
  };

  render() {
    const { tabAdd, tabSelect, selectedTab } = this.props;
    var { emails } = this.props;
    return (
      <s.SideMenuView>
        <s.ButtonWrapper>
          <Button
            selected={this.state.buttonFilter === "todas"}
            label="Todas"
            handleClick={() => this.setState({ buttonFilter: "todas" })}
          />
          <Button
            selected={this.state.buttonFilter === "aguardando_intervencao"}
            label="Problemas"
            handleClick={() =>
              this.setState({ buttonFilter: "aguardando_intervencao" })
            }
          />
          <Button
            selected={this.state.buttonFilter === "resolvido"}
            label="Resolvidas"
            handleClick={() => this.setState({ buttonFilter: "resolvido" })}
          />
        </s.ButtonWrapper>

        <SearchBar searchFilter={t => this.setState({ searchFilter: t })} />

        <s.TextWrapper>
          <s.Icone src={icons.email} />
          <s.Text>Caixa de Entrada</s.Text>
        </s.TextWrapper>

        <s.Line />

        {emails.loading ? (
          <Loader size={35} />
        ) : (
          <EmailList
            searchFilter={this.state.searchFilter}
            filter={this.state.buttonFilter}
            selectedTab={selectedTab}
            tabSelect={tabSelect}
            handleTabClose={this.handleTabClose}
            handleTabPositionChange={this.handleTabPositionChange}
            tabAdd={tabAdd}
            data={this.contentFilter()}
          />
        )}
      </s.SideMenuView>
    );
  }
}

const mapStateToProps = state => {
  return {
    emails: state.emails
  };
};

export default connect(mapStateToProps)(SideMenu);
