import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

const CustomScrollbars = props => {
  const renderView = ({ style }) => {
    const viewStyle = {
      padding: "0px 10px 0px 12px"
    };
    return <div className="box" style={{ ...style, ...viewStyle }} />;
  };

  const renderThumb = ({ style }) => {
    const thumbStyle = {
      backgroundColor: "#434343",
      borderRadius: "5px"
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  };

  const ThumbX = ({ style }) => {
    const thumbStyle = {
      overflow: "hidden"
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  };

  return (
    <Scrollbars
      renderView={renderView}
      renderThumbHorizontal={ThumbX}
      renderThumbVertical={renderThumb}
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={200}
      onScroll={e => props.onScroll(e)}
      ref={e => props.referencia(e)}
      {...props}
    />
  );
};

export default CustomScrollbars;
