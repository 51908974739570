import styled from "styled-components";
import { Colors } from "../../assets/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to right top,
    ${Colors.black2},
    ${Colors.blackSecondary}
  );
  border-radius: 4px;
  box-shadow: 2px 1px 1px 1px ${Colors.black};
  width: 375px;
  padding: 15px 10px;
`;

export const Title = styled.label`
  font-size: 18px;
  margin-bottom: 40px;
  align-self: center;
`;

export const Error = styled.label`
  margin-top: 10px;
  font-size: 14px;
  align-self: center;
  color: #ff3636;
`;

export const Input = styled.input`
  outline: none;
  display: flex;
  border-radius: 5px;
  width: 100%;
  height: 32px;
`;

export const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background: ${Colors.orange};
  border: 0px;
  border-radius: 5px;
  height: 32px;
  font-size: 20px;
  transition-duration: 0.4s;
  :disabled {
    cursor: not-allowed;
  }
  :hover:enabled {
    background: ${Colors.orangeSecondary};
  }
`;

export const Label = styled.label`
  font-size: 16px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0px 0px 30px 0px;
`;
