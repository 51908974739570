import React, { useState } from "react";
import * as s from "./styled";
import { Loader } from "./../../index";

const Resolvido = props => {
  const [isLoading, setIsLoading] = useState(props.isLoading);

  return (
    <>
      <s.LabelContainer>
        <s.Label>
          Tem certeza que deseja confirmar de forma manual este e-mail?
        </s.Label>
      </s.LabelContainer>
      <s.SendButtonContainer>
        <s.SendButton
          onClick={() => props.Resolvido("Resolvido de forma manual")}
        >
          Confirmar
        </s.SendButton>
        <s.CancelButton onClick={() => props.closeModal()}>
          Cancelar
        </s.CancelButton>
      </s.SendButtonContainer>
      {isLoading && <Loader size={35} />}
    </>
  );
};

export default Resolvido;
