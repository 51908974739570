import React, { useState } from "react";
import * as s from "./styled";
import { Loader } from "./../../index";

const SolicitaCNPJ = props => {
  const [isLoading, setIsLoading] = useState(props.isLoading);

  const Confirm = () => {
    props.SolicitaCnpj();
    setIsLoading(true);
  };

  return (
    <>
      <s.LabelContainer>
        <s.Label>Tem certeza que deseja solicitar o CNPJ?</s.Label>
      </s.LabelContainer>
      <s.SendButtonContainer>
        <s.SendButton onClick={() => Confirm()}>Confirmar</s.SendButton>
        <s.CancelButton onClick={() => props.closeModal()}>
          Cancelar
        </s.CancelButton>
      </s.SendButtonContainer>
      {isLoading && <Loader size={35} />}
    </>
  );
};

export default SolicitaCNPJ;
