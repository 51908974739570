import React from "react";
import Modal from "react-modal";
import { QuitButton } from "./../";
import * as s from "./styled";

const customStyles = {
  content: {
    minHeight: "200px",
    minWidth: "400px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "2px 14px 0px 14px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)"
  }
};

const ModalContainer = props => {
  Modal.setAppElement("body");
  return (
    <Modal
      style={customStyles}
      isOpen={props.isOpen}
      onRequestClose={() => props.closeModal()}
    >
      <s.HeaderContainer>
        <s.titleLabel>{props.title}</s.titleLabel>
        <s.exitButton>
          <QuitButton
            onClick={() => props.closeModal()}
            id={"modal-quit-button-close-modal"}
          />
        </s.exitButton>
      </s.HeaderContainer>
      {props.children}
    </Modal>
  );
};

export default ModalContainer;
