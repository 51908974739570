import { createTypes } from "reduxsauce";

export const dashboardTypes = createTypes(
  `
    SET_DASHBOARD_DATA
    `,
  { prefix: "DASHBOARD/" }
);

const setDashboardData = data => {
  return async dispatch => {
    dispatch({
      type: dashboardTypes.SET_DASHBOARD_DATA,
      dashboard: data
    });
  };
};

export const dashboardActions = {
  setDashboardData
};
