import axios from "axios";

const TODOS_EMAILS = "email/consultar/todos";
const CONVERSA = "email/consultar/conversa/";
const SOLICITAR_CNPJ = "email/enviar/solicitarCnpj";
const EMAIL_MANUAL = "email/enviar/manual";
const VISUALIZAR_EMAIL = "/email/visualizada/";
const RELATAR_ERRO = "/email/informarErro";
const RESOLVER_EMAIL = "/email/status/resolvido";
const SOLICITAR_DUPLICATA_NOTA = "/email/enviar/solicitarNota";
const CONSULTA_BOLETO = "/email/consultar/boleto";
const ENVIA_BOLETO = "/email/enviar/boleto";

const api = axios.create({
  baseURL: "https://robomail-duplicata-server-v1.web.app",
  // baseURL: "http://localhost:5000/",
  timeout: 10000,
  headers: {
    AcceptedLanguage: "pt-br",
    "Content-Type": "application/json"
  }
});
async function getConfigs(appToken) {
  let token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkIjoiMjAxOS0xMC0zMFQxOTowNDoxOS40MjVaIiwiaWF0IjoxNTcyNDYyMjU5fQ.D6gbarjxoU8bc1DVTLDQqrTS7RBRzqjSTvScDQGJTNw";
  return {
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {})
    }
  };
}
export const getEmails = async () => {
  const config = await getConfigs(true);
  const url = `${TODOS_EMAILS}`;

  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(async resp => resolve(resp))
      .catch(e => {
        console.log("e", e.response);
        reject(e.response);
      });
  });
};

export const getConversa = async id => {
  const config = await getConfigs(true);
  const url = `${CONVERSA}${id}`;

  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(async resp => resolve(resp))
      .catch(e => {
        console.log("e", e.response);
        reject(e.response);
      });
  });
};

export const postCNPJ = (user, id) => {
  return new Promise((resolve, reject) => {
    getConfigs(true)
      .then(config => {
        const url = `${SOLICITAR_CNPJ}`;
        let body = {
          documentoID: id,
          usuario: user
        };
        api.post(url, body, config).then(resp => {
          console.log(resp);
          resolve(resp);
        });
      })
      .catch(e => reject(e));
  });
};

export const postDuplicataNota = (user, id) => {
  return new Promise((resolve, reject) => {
    getConfigs(true)
      .then(config => {
        const url = `${SOLICITAR_DUPLICATA_NOTA}`;
        let body = {
          documentoID: id,
          usuario: user
        };
        api.post(url, body, config).then(resp => {
          console.log(resp);
          resolve(resp);
        });
      })
      .catch(e => reject(e));
  });
};

export const postEmailManual = (user, id, message) => {
  return new Promise((resolve, reject) => {
    getConfigs(true)
      .then(config => {
        const url = `${EMAIL_MANUAL}`;
        let body = {
          documentoID: id,
          usuario: user,
          mensagem: message
        };
        api.post(url, body, config).then(resp => {
          console.log(resp);
          resolve(resp);
        });
      })
      .catch(e => reject(e));
  });
};

export const getReadEmail = async id => {
  const config = await getConfigs(true);
  const url = `${VISUALIZAR_EMAIL}${id}`;
  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(async resp => resolve(resp))
      .catch(e => {
        console.log("e", e);
        reject(e.response);
      });
  });
};

export const postRelatarErro = (user, id, message) => {
  return new Promise((resolv, reject) => {
    getConfigs(true)
      .then(configs => {
        const url = `${RELATAR_ERRO}`;
        let body = {
          documentoID: id,
          usuario: user,
          mensagem: message
        };
        api.post(url, body, configs).then(resp => {
          console.log("resp", resp);
          resolv(resp);
        });
      })
      .catch(e => reject(e));
  });
};

export const postResolverEmail = (user, id, message) => {
  return new Promise((resolv, reject) => {
    getConfigs(true)
      .then(configs => {
        const url = `${RESOLVER_EMAIL}`;
        let body = {
          documentoID: id,
          usuario: user,
          mensagem: message
        };
        api.post(url, body, configs).then(resp => {
          console.log("resp", resp);
          resolv(resp);
        });
      })
      .catch(e => reject(e));
  });
};

export const postConsultaBoleto = (cnpj, duplicata, notaFiscal) => {
  return new Promise((resolv, reject) => {
    getConfigs(true)
      .then(configs => {
        let nota = null;
        let dupli = null;
        if (duplicata === "") {
          dupli = false;
        } else {
          dupli = duplicata.split(",").map(item => item.trim());
        }

        if (notaFiscal === "") {
          nota = false;
        } else {
          nota = notaFiscal.split(",").map(item => item.trim());
        }

        const url = `${CONSULTA_BOLETO}`;
        let body = {
          cnpj: cnpj,
          notaFiscal: nota,
          duplicata: dupli
        };

        api
          .post(url, body, configs)
          .then(resp => {
            resolv(resp);
          })
          .catch(e => {
            console.log("error", e);
            reject(e);
          });
      })
      .catch(e => {
        console.log("error", e);
        reject(e);
      });
  });
};

export const postEnviaBoleto = (id, cnpj, user, docs) => {
  return new Promise((resolv, reject) => {
    getConfigs(true)
      .then(configs => {
        const url = `${ENVIA_BOLETO}`;
        let body = {
          documentoID: id,
          cnpj,
          usuario: user,
          boletos: docs
        };
        api
          .post(url, body, configs)
          .then(resp => {
            console.log("resp", resp);
            resolv(resp);
          })
          .catch(e => {
            console.log("error", e);
            reject(e);
          });
      })
      .catch(e => {
        console.log("error", e);
        reject(e);
      });
  });
};

// =================== DOCUMENTAR =====================
// URL DEFAULT: https://robomail.firebaseapp.com/
// BEARER TOKEN: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkIjoiMjAxOS0xMC0zMFQxOTowNDoxOS40MjVaIiwiaWF0IjoxNTcyNDYyMjU5fQ.D6gbarjxoU8bc1DVTLDQqrTS7RBRzqjSTvScDQGJTNw
// ROTAS
// GET
// Todos Email: email/consultar/todos
// GET
// Conversa: email/consultar/conversa/:documentoID
// POST
// Enviar Email: email/enviar/manual
// body:
// {
//   "mensagem": "",
//   "documentoID": "",
//   "usuario": "Felipe Silva" ou “robo”
// }
/*















// -----------------------------------TOKENS AUTHORIZE-------------------------------------------------------------------
​
export const saveNewCustomer = async (ref, data, infos) => {
  const config = await getConfigs(true);
  const url = `${NEW_USER}`;
 
  return new Promise((resolve, reject) => {
    api
      .post(url, body, config)
      .then(async resp => {
        const {token} = resp.data;
        setToken(token).then(resolve(resp));
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
​
export const getNewToken = async (userID, infos) => {
  const config = await getConfigs(true);
  const url = `${NEW_TOKEN}/${userID}`;
  const body = {infos};
​
  return new Promise((resolve, reject) => {
    api
      .post(url, body, config)
      .then(async resp => {
        const {token} = resp.data;
        setToken(token).then(resolve(resp));
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
​
export const deleteToken = async () => {
  const config = await getConfigs();
  const url = `${DELETE_TOKEN}`;
  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(resp => {
        removeToken().then(resolve(true));
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
​
export const validateCnpj = async cnpj => {
  const config = await getConfigs(true);
  const url = `${VALIDAR_CNPJ}/${cnpj}`;
​
  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(resp => {

        resolve(resp);
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
​
// -------------------------------------HOME---------------------------------------------------------------------
export const getFeed = async (lastDoc, newDoc) => {
  const config = await getConfigs();
  let url = `${HOME_FEED}`;
  let body = {
    lastDoc: lastDoc ? lastDoc : null,
    newDoc: newDoc ? true : false,
  };
​
  return new Promise((resolve, reject) => {
    api
      .post(url, body, config)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
​
// -----------------------------------TRANSACOES-------------------------------------------------------------------
​
export const getTransacao = async param => {
  const config = await getConfigs();
  // const param = getSatusParam(status);
​
  let url;
​
  if (param) url = `${TRANSACAO_CONSULTAR}/${param}`;
  else url = `${TRANSACAO_CONSULTAR}`;
  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
​
export const pagarTransacao = async (documentoID, body) => {
  const config = await getConfigs();
  let url = `${TRANSACAO_PAGAR}/${documentoID}`;
​
  return new Promise((resolve, reject) => {
    apiPagamento
      .post(url, body, config)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        //Timeout
        if (error.code === 'ECONNABORTED') {
          let data = {
            status: 408,
            data: 'Não foi possível se comunicar com o servidor.',
          };
          reject(data);
        }
        reject(error.response);
      });
  });
};
​
export const getTransacoes = async param => {
  const config = await getConfigs();
  if (param) {
    let url = `${TRANSACAO_CONSULTAR}/entrada/${param}`;
  } else {
    let url = `${TRANSACAO_CONSULTAR}/entrada/`;
  }
  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
​
export const updateTransacao = async (documentoID, acao) => {
  const config = await getConfigs();
  let url = `${TRANSACAO_UPDATE}/${documentoID}`;
  let body = {acao};
​
  return new Promise((resolve, reject) => {
    api
      .post(url, body, config)
      .then(resp => {
        resolve(true);
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
​
export const getOrderDetail = async documentoID => {
  const config = await getConfigs();
  let url = `${TRANSACAO_DETALHES}/${documentoID}`;
  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(resp => {
        resolve(resp.data.detalhes);
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
​
export const getReceipt = async documentoID => {
  const config = await getConfigs();
  let url = `${TRANSACAO_COMPROVANTE}/${documentoID}`;
  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
​
//-----------------------------------USUARIO-------------------------------------------------------------------
​
export const getUsuario = async () => {
  const config = await getConfigs();
  let url = `${CUSTOMER_PERFIL}`;
  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
export const getLimite = async () => {
  const config = await getConfigs();
  let url = `${CUSTOMER_LIMITE}`;
  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
export const getLimiteAtualizado = async () => {
  const config = await getConfigs();
  let url = `${CUSTOMER_LIMITE_ATUALIZADO}`;
  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(e => {
        resolve(false);
      });
  });
};
​
export const savePassword4 = async password4 => {
  const config = await getConfigs();
  let url = `${NEW_PASSWORD4}`;
  let body = {password: password4};
  return new Promise((resolve, reject) => {
    api
      .post(url, body, config)
      .then(resp => {
        console.log('sucesso!password4');
        resolve(resp.data);
      })
      .catch(e => {
        console.log('e', e.response.data);
        reject(e.response);
      });
  });
};
​
export const validatePassword4 = async password4 => {
  const config = await getConfigs();
  let url = `${VALIDATE_PASSWORD4}`;
  let body = {password: password4};
  return new Promise((resolve, reject) => {
    api
      .post(url, body, config)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(e => {
        reject(e.response);
      });
  });
};
​
export const havePassword4 = async () => {
  const config = await getConfigs();
  let url = `${HAVE_PASSWORD4}`;
  return new Promise((resolve, reject) => {
    api
      .post(url, null, config)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(e => {
        reject(e.response);
      });
  });
};
​
// ---------------------------------------CARTAO-------------------------------------------------------------------
export const getCartao = async () => {
  const config = await getConfigs();
  let url = `${CARTAO_CONSULTAR}`;
​
  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
        // console.log("e", e.response.data);
        // reject(e.response);
      });
  });
};
*/
