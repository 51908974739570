import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 94px;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0px;
`;
