import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const exitButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
`;

export const titleLabel = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0px;
`;
