import { createGlobalStyle } from 'styled-components';
import { Colors } from '../assets/theme';
// import "./fonts/segoe-ui-bold.otf";

const colors = {
  placeholder: '#979797'
};

const font = {
  weight: {
    bold: '300',
    normal: '200',
    small: '100'
  }
};

export const GlobalStyle = createGlobalStyle`
  * {
    padding: 0%;
  }

  label {
    color: white;
    margin: 0px;
    font-weight: ${font.weight.normal};
  }

  body, html, #root, container {
    margin: 0;
    padding: 0;
    background-color: ${Colors.black} !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, sans-serif !important;
  }

  a {
    cursor: pointer;
  }

  li {
    cursor: pointer;
    list-style: none;
  }

  ::placeholder {
    /* opacity: 1; */
    color: ${colors.placeholder};
    font-size: 13px;
    font-weight: ${font.weight.small};
  }
`;
