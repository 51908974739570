import { createTypes } from "reduxsauce";

export const emailsTypes = createTypes(`SET_NEW_EMAILS`, {
  prefix: "EMAILS/"
});

var resp = [];
const Emails = dados => {
  if (!Object.keys(dados).find(item => item == 0)) {
    resp.push(dados);
  } else {
    resp = dados;
  }

  return dispatch => {
    dispatch({
      type: emailsTypes.SET_NEW_EMAILS,
      emails: resp,
      loading: false
    });
  };
};

export const emailsActions = {
  Emails
};
