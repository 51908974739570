import React, { useState } from "react";
import { icons } from "../../assets/icons/";

const QuitButton = props => {
  const [img, setImg] = useState(icons.close);
  return (
    <img
      onClick={() => props.onClick()}
      id="button-component-quit"
      onMouseOver={() => setImg(icons.closeRed)}
      onMouseOut={() => setImg(icons.close)}
      src={img}
      style={{ width: props.width || "20px", cursor: "pointer" }}
      alt={"Botao que fechará a modal aberta"}
    />
  );
};

export default QuitButton;
