import React, { Component } from "react";
import { icons } from "../../assets/icons";
import * as s from "./style.body";
import { ModalContainer } from "../../components";
import { Scrollbars } from "react-custom-scrollbars";
import "react-tiny-fab/dist/styles.css";

export default class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      fileName: [],
      fileContent: {},
      posicao: ""
    };
  }

  componentDidMount() {
    this.setFiles();
    this.scrollBar.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    if (this.props.chat !== prevProps.chat) {
      this.setFiles();
    }
  }

  setFiles = () => {
    let filesTmp = [];
    let buf = [];
    this.props.chat.map(mensagem => {
      mensagem.anexos &&
        mensagem.usuario === "robo" &&
        mensagem.anexos.map((element, index) => {
          filesTmp.push(element.filename);
          if (
            element.content.data !== null ||
            element.content.data !== undefined
          ) {
            buf.push(element.content.data);
          }
        });
      this.setState({ fileName: filesTmp, fileContent: buf });
    });
  };

  renderChat = () => {
    const { chat } = this.props;
    return chat.map((mensagem, index) => {
      let { recebido, alerta, corpo, data, anexos, usuario } = mensagem;
      let dataMensagem = new Date(data);
      let show = false;
      if (usuario !== "robo" && !recebido && !alerta && usuario) {
        show = true;
      }
      return (
        <React.Fragment key={index}>
          <s.MensagemLine recebido={recebido}>
            <s.MensagemWrapper recebido={recebido}>
              <s.Corpo alerta={alerta}>{corpo}</s.Corpo>
              <s.flexFiles>
                {anexos && !recebido
                  ? anexos.map((element, index) => (
                      <s.fileContainer
                        key={index}
                        id={"btn-file-chat"}
                        onClick={() =>
                          this.setState({ isOpen: true, posicao: index })
                        }
                      >
                        <s.fileTitle>
                          <s.iconContainer src={icons.pdf}></s.iconContainer>
                          <s.titleContainer>
                            {element.filename}
                          </s.titleContainer>
                        </s.fileTitle>
                        <div id={"bottom"}></div>
                      </s.fileContainer>
                    ))
                  : null}
              </s.flexFiles>
              <s.MensagemInfo>
                <s.Info>
                  {show && `${usuario} - `}
                  {dataMensagem.toLocaleDateString("pt-br")} -
                  {dataMensagem.toLocaleTimeString("pt-br")}
                </s.Info>
                {!recebido ? (
                  usuario === "robo" || usuario === undefined ? (
                    alerta ? (
                      <s.Image src={icons.robot_chat} />
                    ) : (
                      <s.Image src={icons.robot_chat_blue} />
                    )
                  ) : (
                    <s.Image src={icons.user_chat_blue} size="22px" />
                  )
                ) : null}
              </s.MensagemInfo>
            </s.MensagemWrapper>
          </s.MensagemLine>
        </React.Fragment>
      );
    });
  };

  render() {
    const { isOpen, fileName, fileContent, posicao } = this.state;
    return (
      <>
        {isOpen && (
          <ModalContainer
            isOpen={isOpen}
            closeModal={() => this.setState({ isOpen: false })}
            fileName={fileName[posicao]}
            fileContent={fileContent[posicao]}
          />
        )}
        <s.Container>
          <Scrollbars
            renderTrackHorizontal={props => (
              <div {...props} style={{ display: "none" }} />
            )}
            ref={e => (this.scrollBar = e)}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
          >
            {this.renderChat()}
          </Scrollbars>
        </s.Container>
      </>
    );
  }
}
