import React, { Component } from "react";
import * as s from "./style.footer";
import { icons } from "./../../assets/icons";
import { Loader } from "../../components";

export default class Footer extends Component {
  constructor() {
    super();
    this.state = {
      message: "",
      isOpen: false,
      modal: ""
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  enterPressed = event => {
    const { message } = this.state;
    var code = event.keyCode || event.which;
    if (code === 13 && message !== "") {
      this.props.enviaEmailManual(message);
    }
  };

  renderModal = (isOpen, type) => {
    this.setState({
      isOpen,
      modal: type
    });
  };

  render() {
    const { enviaEmailManual, isLoading } = this.props;
    const { message } = this.state;
    return (
      <s.Container onKeyPress={e => this.enterPressed(e)}>
        <s.InputWrapper>
          <s.Input
            name="inputContent"
            type="text"
            placeholder="Digite aqui"
            onChange={e => this.handleChange(e)}
            name="message"
            value={message}
          />
          <s.Icon src={icons.robot} />
        </s.InputWrapper>
        <s.ButtonWrapper>
          {isLoading ? (
            <Loader size={27} margin={"0 10px 0 10px"} />
          ) : (
            <s.Send
              src={icons.send}
              onClick={() => {
                enviaEmailManual(message);
                this.setState({ message: "" });
              }}
            />
          )}
        </s.ButtonWrapper>
      </s.Container>
    );
  }
}
