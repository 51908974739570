const INITIAL_STATE = {
  initialized: false
};

export const app = (state = INITIAL_STATE, action) => {
  if (action.type === "APP/SET_INITIALIZED") {
    return { ...state, initialized: action.initialized };
  }
  return state;
};
