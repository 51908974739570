import React, { useState } from "react";
import InputMask from "react-input-mask";
import * as s from "./styled";
import { Loader } from "./../../index";

const ConsultaBoleto = props => {
  const [cnpj, setCnpj] = useState("");
  // 97.544.920/0001-02
  const [duplicata, setDuplicata] = useState("");
  const [nota, setNota] = useState("");
  // 564535, 845322, 845827

  const disabled = () => {
    if (cnpj !== "" && (nota !== "" || duplicata !== "")) {
      return false;
    }
    return true;
  };

  return props.isLoading ? (
    <s.LoaderContainer>
      <Loader size={45} />
    </s.LoaderContainer>
  ) : (
    <s.Container>
      <s.Line />
      <s.RequiredContainer>
        <InputMask
          style={{
            width: "154px",
            height: "28px",
            background: "#fff",
            border: "1px solid #ddd",
            borderRadius: "5px",
            marginTop: "11px",
            color: "#484848",
            fontSize: "14px",
            padding: "5px",
            outline: "none"
          }}
          mask="99.999.999/9999-99"
          maskChar={null}
          placeholder="00.000.000/0000-00"
          onChange={e => {
            setCnpj(e.target.value);
          }}
        />
        <s.Required>*</s.Required>
      </s.RequiredContainer>
      <s.Input
        id="input-duplicata-modal-consulta-boleto"
        value={duplicata}
        placeholder="Duplicatas"
        onChange={e => {
          setDuplicata(e.target.value);
        }}
      />
      <s.Input
        id="input-nota-modal-consulta-boleto"
        value={nota}
        placeholder="Notas: 0000, 0001..."
        onChange={e => {
          setNota(e.target.value);
        }}
      />
      <s.SendButtonContainer>
        <s.SendButton
          onClick={() => {
            props.consultaBoleto(cnpj, duplicata, nota);
          }}
          disabled={disabled()}
        >
          Consultar
        </s.SendButton>
      </s.SendButtonContainer>
    </s.Container>
  );
};

export default ConsultaBoleto;
