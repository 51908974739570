import React, { Component } from "react";
import { Container, ContentChatView, ErrorMesage } from "./styled";
import { SideMenu, Chat, Dashboard, Loader } from "../../components";
import { Tabs, Tab } from "react-draggable-tab";
import "./home.css";
import * as Database from "../../utils/database";
import { createBrowserHistory } from "history";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        <Tab key={"0"} title={"Dashboard"} unclosable={true}>
          {null}
        </Tab>
      ],
      selectedTab: "0",
      emailsData: [],
      isLoading: true,
      chatContent: [],
      conversas: {},
      filter: "todas",
      history: createBrowserHistory(),
      error: false
    };
  }

  componentDidMount() {
    const { id, emails } = this.props;
    if (id !== "landingpage" && id !== "login") {
      let urlEmail = emails.emails.find(email => email.doc_id === id);
      urlEmail && this.tabAdd(id, urlEmail.assunto);
    }
  }

  getChat = docId => {
    const { conversas } = this.state;
    Database.getConversa(docId)
      .then(resp => {
        conversas[docId] = resp.data.emailList.mensagens;
        this.setState({
          conversas
        });
      })
      .catch(e => {
        this.setState({
          isLoading: false
        });
        return (
          <ErrorMesage>
            Desculpe, não foi possivel carregar a conversa
          </ErrorMesage>
        );
      });
  };

  tabSelect = (e, key, currentTabs) => {
    this.setState({ selectedTab: key, tabs: currentTabs });
    const { selectedTab, history } = this.state;
    if (selectedTab !== key) {
      history.push(`/landingpage/${key !== 0 ? key : null}`);
    }
  };
  handleTabClose(e, key, currentTabs) {
    this.setState({ tabs: currentTabs });
    const { selectedTab, history } = this.state;
    if (selectedTab === key) {
      history.push(`/landingpage/${key !== 0 ? key : null}`);
    }
  }
  tabAdd = (id, assunto) => {
    const currentTabs = this.state.tabs;
    const teste = currentTabs.find(tab => tab.key === id);
    if (teste) {
      this.setState({ selectedTab: id, tabs: currentTabs });
    } else {
      let newTab = (
        <Tab key={id} title={assunto ? assunto : "Sem Assunto"}>
          {null}
        </Tab>
      );
      let newTabs = currentTabs.concat([newTab]);
      this.setState({
        tabs: newTabs,
        selectedTab: id
      });
    }
    this.state.history.push(`/landingpage/${id}`);
    this.getChat(id);
  };

  renderContent = () => {
    var { selectedTab, conversas } = this.state;
    const { emails } = this.props;

    if (selectedTab === "0") {
      return <Dashboard />;
    } else {
      if (conversas[selectedTab]) {
        let element = emails.emails.find(email => email.doc_id === selectedTab);
        return (
          <Chat
            header={element}
            docId={selectedTab}
            chat={conversas[selectedTab]}
            getChat={this.getChat}
          />
        );
      } else {
        return <Loader size={80} />;
      }
    }
  };

  render() {
    const { tabs, selectedTab, error } = this.state;

    if (error) {
      return (
        <ErrorMesage>
          Desculpe, não foi possível trazer as informações no momento
        </ErrorMesage>
      );
    } else {
      return (
        <Container>
          <SideMenu
            tabSelect={this.tabSelect}
            tabAdd={this.tabAdd}
            handleTabClose={this.handleTabClose}
            selectedTab={selectedTab}
          />
          <ContentChatView>
            <Tabs
              tabsClassNames={tabsClassNames}
              disableDrag={false}
              selectedTab={selectedTab}
              onTabSelect={this.tabSelect}
              onTabClose={this.handleTabClose.bind(this)}
              tabAddButton={null}
              tabs={tabs}
            />
            {this.renderContent()}
          </ContentChatView>
        </Container>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    emails: state.emails
  };
};

export default connect(mapStateToProps)(withRouter(Home));

const tabsClassNames = {
  tabWrapper: "myWrapper",
  tabBar: "myTabBar",
  tabBarAfter: "myTabBarAfter",
  tab: "myTab",
  tabTitle: "myTabTitle",
  tabCloseIcon: "tabCloseIcon",
  tabBefore: "myTabBefore",
  tabAfter: "myTabAfter"
};
