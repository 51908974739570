import styled from "styled-components";

export const Container = styled.div`
  background: rgb(46, 46, 46);
  display: flex;
  padding: 10px 0 10px 39px;
  align-items: center;
  height: 75px;
  @media (max-height: 650px) {
    padding: 5px 0px 5px 39px;
  }
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  overflow: hidden;
`;

export const TextItem = styled.div`
  display: flex;
  align-items: center;
`;

export const Nome = styled.label`
  color: white;
  font-size: 17px;
  font-weight: 700;
`;

export const Email = styled.label`
  display: flex;
  color: #e4e4e4;
  font-size: 14px;
  height: 20px;
  margin-left: 10px;
`;

export const Assunto = styled.label`
  color: white;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Icone = styled.img`
  width: 45px;
  height: 45px;
  @media (max-height: 475px) {
    width: 30px;
    height: 30px;
  }
`;

export const IconeContainer = styled.div`
  width: 46px;
  height: 46px;
  @media (max-height: 475px) {
    width: 31px;
    height: 31px;
  }
`;
