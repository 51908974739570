import React, { useState } from "react";
import { CustomScrollbars, Loader } from "./../index";
import * as s from "./styled";
import { icons } from "../../assets/icons";
import { getReadEmail } from "../../utils/database";
import { firebase } from "../../utils/firebase";
import { useSelector } from "react-redux";

const EmailListScreen = ({
  data,
  tabAdd,
  selectedTab,
  filter,
  searchFilter
}) => {
  const [scrollRef, setScrollRef] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const emails = useSelector(state => state.emails);
  const { dashboardData } = useSelector(state => state.dashboard);

  const renderIcon = status => {
    return (
      <div
        style={{
          width: "20px",
          height: "20px",
          background: status === 2 ? "#004da6" : "#FBBD33",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: "0.85"
        }}
      >
        {status === 2 ? (
          <s.Icone src={icons.hourglass} size={"10px"} title="processando" />
        ) : (
          <s.Icone src={icons.dots} size={"15px"} title="aguardando resposta" />
        )}
      </div>
    );
  };

  const EmailItem = (item, index) => {
    let status = "";
    if (item.status === "resolvido") status = icons.check;
    else if (item.status === "aguardando_resposta") status = 1;
    else if (item.status === "processando") status = 2;
    else status = icons.alert;
    return (
      <s.EmailWrapper
        selected={selectedTab === item.doc_id}
        key={index}
        onClick={() => {
          tabAdd(item.doc_id, item.assunto ? item.assunto : item.email);
          {
            return item.visualizada ? null : getReadEmail(item.doc_id);
          }
        }}
      >
        {!item.visualizada ? (
          <s.ReadContainer>
            <s.ReadEmail />
          </s.ReadContainer>
        ) : null}
        <s.EmailView>
          <s.Email>
            <nobr>{item.remetente_email}</nobr>
          </s.Email>
          <s.Assunto>{item.assunto ? item.assunto : "Sem Assunto"}</s.Assunto>
        </s.EmailView>
        <div>
          {typeof status !== "number" ? (
            <s.Icone src={status} title={item.status.replace("_", " ")} />
          ) : (
            renderIcon(status)
          )}
        </div>
      </s.EmailWrapper>
    );
  };

  const renderEmails = () => {
    let resp = [];
    if (filter === "todas" && searchFilter === "") {
      data.sort((a, b) => {
        if (a.data > b.data) {
          return -1;
        } else {
          return 1;
        }
      });
    }

    for (let i in data) {
      resp.push(EmailItem(data[i], i));
    }

    return resp;
  };

  const handeScroll = async () => {
    let qtd = 30;

    if (scrollRef.getValues().top >= 0.8 && !isLoading) {
      if (dashboardData.emails_total - emails.emails.length > 30) {
        qtd = 30;
      } else if (dashboardData.emails_total === emails.emails.length) {
        return null;
      } else {
        qtd = dashboardData.emails_total - emails.emails.length;
      }
      setIsLoading(true);
      await firebase
        .getEmailRealtime(emails.emails.length + qtd, emails.emails.length)
        .then(() => {
          setIsLoading(false);
        })
        .catch(e => {
          setIsLoading(false);
          console.log("e", e);
        });
    }
  };

  const EmailList = () => {
    let list = renderEmails();
    return (
      <CustomScrollbars
        style={{ height: "calc(100vh - 115px)" }}
        onScroll={handeScroll}
        referencia={setScrollRef}
      >
        {list.map(e => e)}
        {isLoading && <Loader size={20} height={"50px"} />}
      </CustomScrollbars>
    );
  };
  if (data) {
    return EmailList();
  } else {
    return <s.NotFound>Nenhum email encontrado</s.NotFound>;
  }
};

export default EmailListScreen;
