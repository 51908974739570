export const icons = {
  alert: require("./sidemenu/alert-icon.png"),
  check: require("./sidemenu/check-icon.png"),
  search: require("./searchbar/search-icon.png"),
  person: require("./header/person-icon.png"),
  send: require("./footer/ios-paper-plane.png"),
  robot: require("./footer/robot-icon.png"),
  robot_whit: require("./robot-white-icon.png"),
  chat: require("./chatbox-icon.png"),
  grafh: require("./graph-icon.png"),
  robot_chat: require("./chat/robot-chat.svg"),
  plane_chat: require("./chat/plane.svg"),
  robot_chat_blue: require("./chat/robot-chat-blue.svg"),
  user_chat: require("./chat/user.svg"),
  user_chat_blue: require("./chat/user_blue.svg"),
  hourglass: require("./sidemenu/hourglass.svg"),
  dots: require("./sidemenu/dots.svg"),
  close: require("./close.png"),
  closeRed: require("./closeRed.svg"),
  pdf: require("./md-document.svg"),
  envia_boleto: require("./footer/envia-boleto.svg"),
  erro: require("./footer/erro.svg"),
  alert_white: require("./error.svg"),
  nota_duplicata: require("./footer/nota-duplicata.svg"),
  resolvido: require("./footer/resolvido.svg"),
  cnpj: require("./footer/solicita-cnpj.svg"),
  add: require("./footer/add.svg"),
  email: require("./sidemenu/email.svg")
};
