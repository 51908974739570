import React from "react";
import { Fab, Action } from "react-tiny-fab";
import { icons } from "../../assets/icons";
import "react-tiny-fab/dist/styles.css";

const FloatingButton = props => {
  return (
    <Fab
      mainButtonStyles={{ background: "#fff", cursor: "auto" }}
      position={{ bottom: -10, right: 0 }}
      icon={<img src={icons.add} alt={"Mais funcionalidades"} />}
      alwaysShowTitle={true}
    >
      <Action
        text="Enviar Boleto"
        style={{ background: "#fff" }}
        onClick={() => props.openModal("consulta-boleto")}
      >
        <img src={icons.envia_boleto} alt={"Envia boletos"} />
      </Action>
      <Action
        text="Resolvido"
        style={{ background: "#fff" }}
        onClick={() => props.openModal("resolvido")}
      >
        <img src={icons.resolvido} alt={"Resolvido"} />
      </Action>
      <Action
        text="Solicitar Nota ou Duplicata"
        style={{ background: "#fff" }}
        onClick={() => props.openModal("solicita-nota-duplicata")}
      >
        <img src={icons.nota_duplicata} alt={"Solicitar Nota ou Duplicata"} />
      </Action>

      <Action
        text="Solicitar CNPJ"
        style={{ background: "#fff" }}
        onClick={() => props.openModal("solicita-cnpj")}
      >
        <img src={icons.cnpj} alt={"Solicitar CNPJ"} />
      </Action>
      <Action
        text="Relatar erro"
        style={{ background: "#fff" }}
        onClick={() => props.openModal("envia-erro")}
      >
        <img src={icons.erro} alt={"Relatar erro"} />
      </Action>
    </Fab>
  );
};

export default FloatingButton;
