import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 250px;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0px;
`;

export const fileContainer = styled.div`
  height: 80px;
  border-radius: 6px;
  background-color: #f6f6f6;
  cursor: pointer;
`;

export const fileTitle = styled.div`
  display: flex;
  height: 35px;
  border-radius: 6px 6px 0px 0px;
  background-color: #dadada;
  justify-content: space-between;
  align-items: center;
  padding: 0px 6px;
`;

export const titleContainer = styled.div`
  font-size: 11px;
  margin-top: 10px;
  justify-content: center;
`;

export const iconContainer = styled.img`
  margin: 5px;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
`;

export const flexFiles = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SendButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 8px;
`;

export const SendButton = styled.button`
  outline: none;
  display: flex;
  justify-content: center;
  width: 80px;
  height: 30px;
  background-color: #3e64c2;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.05s linear;
  transition: 0.4s;
  margin-bottom: 10px;
  ${props => props.disabled && `opacity: 0.7; cursor: not-allowed;`}
`;

export const Line = styled.div`
  height: 1px;
  border: 1px solid #484848;
  display: flex;
  flex: 1;
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 102px;
`;
