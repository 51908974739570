import React, { useState } from "react";
import * as s from "./styled";

const RelataErro = props => {
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);

  const handleChange = value => {
    setMessage(value);

    if (value !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <>
      <s.Line />
      <s.TextArea
        id="textarea-modal-relata-erro"
        rows={4}
        value={message}
        placeholder={"Sua resposta..."}
        onChange={e => handleChange(e.target.value)}
      />
      <s.SendButtonContainer>
        <s.SendButton
          onClick={() => props.relataErroManual(message)}
          disabled={disabled}
        >
          Enviar
        </s.SendButton>
      </s.SendButtonContainer>
    </>
  );
};

export default RelataErro;
