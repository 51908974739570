import styled from "styled-components";

export const Container = styled.div`
  flex-direction: column;
  background: rgb(218, 218, 218);
  display: flex;
  flex: 1;
  align-items: center;
  max-height: 80vh;
  overflow-y: auto;
`;

export const Text = styled.span`
  margin-bottom: 0pt !important;
  font-size: 12px;
`;

export const MensagemLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => (props.recebido ? "flex-start" : "flex-end")};
`;

export const MensagemWrapper = styled.div`
  background: ${props => (props.recebido ? "white" : "rgb(223, 231, 248)")};
  margin: 20px 70px;
  max-width: "60%";
  padding: 5px 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: ${props =>
    props.recebido ? "0px 8px 8px 8px" : "8px 0px 8px 8px"};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Corpo = styled.label`
  color: ${props => (props.alerta ? "rgb(58, 58, 58)" : "black")};
  white-space: pre-line;
  overflow-wrap: break-word;
  max-width: 500px;
  font-size: 14px;
  font-weight: 100;
  font-style: ${props => (props.alerta ? "italic" : "normal")};
`;

export const Image = styled.img`
  margin-left: 10px;
  width: ${props => props.size || "18px"};
  height: ${props => props.size || "22px"};
`;

export const MensagemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Info = styled.label`
  color: #606060;
  display: flex;
  font-size: 14px;
`;

export const fileContainer = styled.div`
  width: 250px;
  height: 80px;
  border-radius: 6px;
  background-color: #f6f6f6;
  cursor: pointer;
  margin: 5px;
`;

export const fileTitle = styled.div`
  display: flex;
  width: 250px;
  height: 35px;
  margin: 45px 0px 0px 0px;
  border-radius: 0px 0px 6px 6px;
  background-color: #dadada;
`;

export const titleContainer = styled.div`
  font-size: 11px;
  margin-top: 10px;
  justify-content: center;
`;

export const iconContainer = styled.img`
  margin: 5px;
`;

export const flexFiles = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

// {
//     "documentoID": "4RNFL47z7S7gwvmsAsm9",
//     "data": "2019-12-06T18:54:36.000Z",
//     "recebido": true,
//     "anexos": [
//     "assinatura_email.png"
//     ],
//     "corpo": "BOM DIA,     PODERIA ME ENVIAR O SEGUINTE BOLETO.     NF 618040  CNPJ 90.890.765.0001-37     ATT  GISELE\n\n\n\n\n",
//     "usuario": "Rafael Feiten "
// },
