import React, { useState, useEffect } from "react";
import * as s from "./style.dash";
import { icons } from "./../../assets/icons";
import { Loader } from "./../index";

import { useSelector } from "react-redux";

const Dashboard = () => {
  const { dashboardData } = useSelector(state => state.dashboard);
  const [loading, setLoadign] = useState(true);

  useEffect(() => {
    if (dashboardData.emails_total) {
      setLoadign(false);
    }
  }, [dashboardData]);

  const total = dashboardData.emails_total;
  const assertividade_acerto = dashboardData.assertividade_acerto;
  const assertividade_erro = dashboardData.assertividade_erro;
  const naoResolvidos = dashboardData.emails_naoResolvidos;
  const naoResolvidos_segundaVia = dashboardData.motivoNaoResolvidos_segundaVia;
  const naoResolvidos_intencao = dashboardData.motivoNaoResolvidos_intencao;
  const resolvidos = dashboardData.emails_resolvidos;
  const processando = dashboardData.emails_processando;
  const aguardando = dashboardData.emails_aguardando;

  return (
    <s.Container>
      <s.Header>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <s.TextHeader fontSize={43} fontWeight={600}>
            Bem-vindo ao seu assistente de E-mails!
          </s.TextHeader>
          <s.TextHeader fontSize={33} fontWeight={300}>
            Um assistente inteligente de cobranças automatizadas
          </s.TextHeader>
        </div>
        <s.Icon src={icons.robot_whit} alt="Nature" size={80} />
      </s.Header>
      <s.Content>
        <s.Box0>
          <s.InfoPanel>
            <s.Icon src={icons.grafh} alt="Nature" size={45} />
            <s.Text fontSize={35} panel>
              Status dos E-mails
            </s.Text>
          </s.InfoPanel>
          <s.Status>
            <s.HeaderStatus>
              <s.Text fontSize={20} fontWeight={500}>
                Assertividade do Algoritmo
              </s.Text>
            </s.HeaderStatus>
            <s.ContentStatus>
              <s.Text fontSize={25} resize>
                Acertos:
              </s.Text>
              {loading ? (
                <Loader size={27} />
              ) : total !== 0 && total !== null ? (
                <s.Text fontSize={25} resize>
                  {((assertividade_acerto / total) * 100).toFixed(1)}%
                </s.Text>
              ) : (
                <s.Text fontSize={25} resize>
                  0
                </s.Text>
              )}

              <s.Text fontSize={25} resize>
                Erros:
              </s.Text>

              {loading ? (
                <Loader size={27} />
              ) : total !== 0 && total !== null ? (
                <s.Text fontSize={25} resize>
                  {((assertividade_erro / total) * 100).toFixed(1)}%
                </s.Text>
              ) : (
                <s.Text fontSize={25} resize>
                  0
                </s.Text>
              )}
            </s.ContentStatus>
          </s.Status>
        </s.Box0>
        <s.Box1>
          <s.AllEmails>
            <s.HeaderAllEmails>
              <s.Icon src={icons.email} alt="Nature" size={25} />
              <s.Text fontSize={25} fontWeight={500} resize>
                Total
              </s.Text>
            </s.HeaderAllEmails>
            <s.ContentEmails>
              {loading ? (
                <Loader size={27} />
              ) : total !== 0 && total !== null ? (
                <s.Text fontSize={40} resize>
                  {total}
                </s.Text>
              ) : (
                <s.Text fontSize={40} resize>
                  0
                </s.Text>
              )}
            </s.ContentEmails>
          </s.AllEmails>
          <s.NotResolv>
            <s.HeaderNotResolv>
              <s.Icon src={icons.alert_white} alt="Nature" size={25} />
              <s.Text fontSize={20} fontWeight={500}>
                Não Resolvidos
              </s.Text>
            </s.HeaderNotResolv>
            <s.ContentNotResolv>
              {total !== 0 && total !== null ? (
                <>
                  <s.Text fontSize={40} resize margin={"10px"}>
                    {naoResolvidos ? naoResolvidos : 0}
                  </s.Text>
                  <s.Text fontSize={40} resize>
                    {((naoResolvidos / total) * 100).toFixed(1)}%
                  </s.Text>
                </>
              ) : (
                <>
                  <s.Text fontSize={40} resize>
                    0
                  </s.Text>
                  <s.Text fontSize={40} resize>
                    0%
                  </s.Text>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "80px",
                  width: "300px",
                  justifyContent: "space-around"
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <s.Text fontSize={15}>Segunda via não encontrada</s.Text>
                  <s.Text fontSize={15}>
                    {naoResolvidos_segundaVia ? naoResolvidos_segundaVia : 0}
                  </s.Text>
                  <s.Text fontSize={15}>
                    {naoResolvidos !== 0 && naoResolvidos !== null
                      ? (
                          (naoResolvidos_segundaVia / naoResolvidos) *
                          100
                        ).toFixed(1)
                      : "0"}
                    %
                  </s.Text>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <s.Text fontSize={15}>Intenção não identificada</s.Text>
                  <s.Text fontSize={15}>
                    {naoResolvidos_intencao ? naoResolvidos_intencao : 0}
                  </s.Text>
                  <s.Text fontSize={15}>
                    {naoResolvidos !== 0 && naoResolvidos !== null
                      ? (
                          (naoResolvidos_intencao / naoResolvidos) *
                          100
                        ).toFixed(1)
                      : 0}
                    %
                  </s.Text>
                </div>
              </div>
            </s.ContentNotResolv>
          </s.NotResolv>
        </s.Box1>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <s.EmailsData>
            <s.Text fontSize={18}>E-mails resolvidos</s.Text>
            <s.EmailsBox color={"#1fb012"}>
              {total !== 0 && total !== null ? (
                <>
                  <s.Text fontSize={40} resize>
                    {resolvidos}
                  </s.Text>
                  <s.Line color={"#198210"}></s.Line>
                  <s.Text fontSize={40} resize>
                    {((resolvidos / total) * 100).toFixed(1)}%
                  </s.Text>
                </>
              ) : (
                <s.Text fontSize={40} resize>
                  0
                </s.Text>
              )}
            </s.EmailsBox>
          </s.EmailsData>
          <s.EmailsData>
            <s.Text fontSize={18}>Processando</s.Text>
            <s.EmailsBox color={"#3E64C2"}>
              {total !== 0 && total !== null ? (
                <>
                  <s.Text fontSize={40} resize>
                    {processando}
                  </s.Text>
                  <s.Line color={"#2C51AD"}></s.Line>
                  <s.Text fontSize={40} resize>
                    {((processando / total) * 100).toFixed(1)}%
                  </s.Text>
                </>
              ) : (
                <s.Text fontSize={40} resize>
                  0
                </s.Text>
              )}
            </s.EmailsBox>
          </s.EmailsData>
          <s.EmailsData>
            <s.Text fontSize={18}>Aguardando Resposta do Cliente</s.Text>
            <s.EmailsBox color={"#FBBC05"}>
              {total !== 0 && total !== null ? (
                <>
                  <s.Text fontSize={40} resize>
                    {aguardando}
                  </s.Text>
                  <s.Line color={"#CC9800 "}></s.Line>
                  <s.Text fontSize={40} resize>
                    {((aguardando / total) * 100).toFixed(1)}%
                  </s.Text>
                </>
              ) : (
                <s.Text fontSize={40} resize>
                  0
                </s.Text>
              )}
            </s.EmailsBox>
          </s.EmailsData>
        </div>
      </s.Content>
    </s.Container>
  );
};

export default Dashboard;
