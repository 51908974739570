const INITIAL_STATE = {
  user: null
};

export const user = (state = INITIAL_STATE, action) => {
  if (action.type === "USER/SET_USER") {
    return { ...state, user: action.user };
  }
  return state;
};
