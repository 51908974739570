import styled from "styled-components";

export const SideMenuView = styled.div`
  width: 275px;
  height: 100%;
  background: #151515;
  justify-content: center;
  align-items: center;
  @media (max-width: 875px) {
    width: calc(10vw + 187px);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  height: 40px;
  padding: 0px 8px;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 7px 0px 2px 12px;
`;

export const Text = styled.label`
  color: white;
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
`;

export const Line = styled.div`
  height: 2px;
  width: 250px;
  background: rgb(67, 67, 67);
  margin: 0px 0px 5px 12px;
  border-radius: 5px;
`;

export const Icone = styled.img`
  width: 20px;
  height: 20px;
`;
