import React, { useState, useEffect } from "react";
import { icons } from "./../../assets/icons/index";
import { Icon, Input, SearchWrapper } from "./styled";
import { Loader } from "./../../components/";

const SearchBar = ({ searchFilter }) => {
  const [changing, setChanging] = useState(0);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState();

  useEffect(() => {
    if (changing) {
      clearTimeout(changing);
    }
    var time = setTimeout(() => {
      if (text !== undefined) {
        searchFilter(text);
        setLoading(false);
      }
    }, 1500);
    setChanging(time);
  }, [text]);

  const handleChange = e => {
    setText(e.target.value);
    setLoading(true);
  };

  return (
    <div>
      <SearchWrapper>
        <Icon>
          <img
            src={icons.search}
            style={{ width: "15px" }}
            alt="lupa de busca"
          ></img>
        </Icon>
        <Input
          name="searchFilter"
          type="text"
          placeholder="Buscar"
          value={text || ""}
          onChange={e => handleChange(e)}
        />
        {loading ? <Loader size={15} height={15} /> : null}
      </SearchWrapper>
    </div>
  );
};

export default SearchBar;
