import React from "react";
import * as s from "./styled";

const Sucesso = props => {
  return (
    <s.Container>
      <s.Title>{props.msg}</s.Title>
    </s.Container>
  );
};

export default Sucesso;
