import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

import { app, user, emails, dashboard, subscribe } from "./reducers/";

const loggerMiddleware = createLogger();

const reducers = combineReducers({ app, user, emails, dashboard, subscribe });

export const store = createStore(
  reducers,
  applyMiddleware(thunkMiddleware, loggerMiddleware)
);

export * from "./actions";
