import { createBrowserHistory } from "history";
import { firebase } from "./firebase";

const delay = (time = 1500) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

const history = createBrowserHistory();

export { delay, firebase, history };
