const Colors = {
  black: "#1d1d1d",
  black2: "#2d2d2d",
  blackSecondary: "#404040",
  blackTertiary: "#454545",
  blackFourth: "#262626",
  whiteSecondary: "#d1d1d1",
  grayTab: "#606060",
  gray: "gray",
  red: "#ab1212",
  yellow: "#d9a807",
  green: "#369d34",
  orange: "rgb(250, 105, 0)",
  orangeSecondary: "#db5f00"
};

module.exports = {
  Colors
};
