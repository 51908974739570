import { firebase } from "../utils";

const collection = "users";

const authListener = (successHandler, errorHandler) => {
  return firebase.auth.onAuthStateChanged(successHandler, errorHandler);
};

const getUser = async id => {
  return await firebase.getDoc(collection, id);
};

const login = user => {
  return new Promise((resolve, reject) => {
    firebase.auth
      .signInWithEmailAndPassword(user.email, user.password)
      .then(resolve())
      .catch(e => resolve(e));
  });
};

const checkUser = () => {
  var user = firebase.auth.currentUser;
  if (user) {
    return user;
  } else {
    return null;
  }
};

const logout = () => {
  firebase.auth.signOut();
};

const register = user => {
  return firebase.auth.createUserWithEmailAndPassword(
    user.email,
    user.password
  );
};

const save = user => {
  const userToSave = { ...user };
  delete userToSave.password;
  if (!userToSave.createdAt) {
    userToSave.createdAt = new Date();
  }
  firebase
    .save(collection, userToSave)
    .catch(err => console.error("Error UserService.save", err));
};

export const userService = {
  authListener,
  getUser,
  login,
  logout,
  register,
  save,
  checkUser
};
