import React, { Component } from "react";
import Header from "./Header.react";
import Body from "./Body.react";
import Footer from "./Footer.react";
import { ChatView } from "./style.chat";
import {
  FloatingButton,
  ContainerModal,
  RelataErro,
  Sucesso,
  Erro,
  EnviaBoleto,
  ConsultaBoleto,
  SolicitaCNPJ,
  SolicitaNotaDup,
  Resolvido
} from "../../components";
import * as Database from "../../utils/database";

export default class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      chat: props.chat,
      docId: props.docId,
      isOpen: false,
      title: "",
      data: null,
      cnpj: "",
      msg: "",
      finalizar: false
    };
  }

  enviaEmailManual = msg => {
    this.setState({ isLoading: true });
    const { docId, getChat } = this.props;
    Database.postEmailManual("Beira Rio", docId, msg)
      .then(() => {
        getChat(docId);
        this.setState({ isLoading: false });
      })
      .catch(e => {
        console.log(e);
      });
  };

  solicitaCnpj = () => {
    const { docId, getChat } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
      Database.postCNPJ("Beira Rio", docId)
        .then(() => {
          getChat(docId);
          this.setState({ isLoading: false, isOpen: false });
        })
        .catch(e => console.log(e));
    }
  };

  solicitaDuplicataNota = () => {
    const { docId, getChat } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
      Database.postDuplicataNota("Beira Rio", docId)
        .then(() => {
          getChat(docId);
          this.setState({ isLoading: false, isOpen: false });
        })
        .catch(e => console.log(e));
    }
  };

  relataErroManual = msg => {
    this.setState({ isLoading: true });
    const { docId, getChat } = this.props;
    Database.postRelatarErro("Beira Rio", docId, msg)
      .then(() => {
        getChat(docId);
        this.setState({
          modal: "sucesso",
          title: "",
          msg: "Erro informado com sucesso!",
          finalizar: false,
          isLoading: false
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  resolveEmail = msg => {
    // this.setState({ isLoading: true, title: "" });
    const { docId, getChat } = this.props;
    Database.postResolverEmail("Beira Rio", docId, msg)
      .then(() => {
        getChat(docId);
        this.setState({
          isLoading: false,
          isOpen: true,
          modal: "sucesso",
          msg: "Este e-mail foi resolvido!",
          finalizar: false
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  consultaBoleto = (cnpj, duplicata, notaFiscal) => {
    this.setState({ isLoading: true, title: "", cnpj });
    const { docId, getChat } = this.props;
    Database.postConsultaBoleto(cnpj, duplicata, notaFiscal)
      .then(resp => {
        if (Object.keys(resp.data.boletosCliente).length !== 0) {
          getChat(docId);
          this.setState({
            modal: "envia-boleto",
            title: "Os boletos abaixo serão enviados",
            data: resp.data.boletosCliente,
            isLoading: false
          });
        } else {
          this.setState({
            modal: "erro",
            title: "",
            isLoading: false
          });
        }
      })
      .catch(e => {
        this.setState({
          modal: "erro",
          isLoading: false
        });
      });
  };

  enviaBoleto = boletos => {
    this.setState({ isLoading: true, title: "" });
    const { cnpj } = this.state;
    const { docId } = this.props;
    Database.postEnviaBoleto(docId, cnpj, "Beira Rio", boletos)
      .then(() => {
        this.setState({
          modal: "sucesso",
          title: "",
          msg: "Boleto(s) enviado(s) com sucesso!",
          isLoading: false,
          finalizar: true
        });
      })
      .catch(() => {
        this.setState({
          modal: "erro",
          isLoading: false
        });
      });
  };

  renderModal = type => {
    //envia-boleto
    if (type === "consulta-boleto") {
      this.setState({
        title: "Informe os campos abaixo",
        // title: "Os boletos abaixo serão enviados",
        isOpen: true,
        modal: type
      });
    } else if (type === "envia-erro") {
      this.setState({
        title: "Qual o motivo do erro?",
        isOpen: true,
        modal: type
      });
    } else if (type === "solicita-cnpj") {
      this.setState({
        title: "Solicitar CNPJ",
        isOpen: true,
        modal: type
      });
    } else if (type === "solicita-nota-duplicata") {
      this.setState({
        title: "Solicitar Nota ou Duplicata",
        isOpen: true,
        modal: type
      });
    } else if (type === "resolvido") {
      this.setState({
        title: "Resolver E-mail",
        isOpen: true,
        modal: type
      });
    }
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  renderContent = () => {
    const { modal, isLoading, data, msg, finalizar } = this.state;
    switch (modal) {
      case "consulta-boleto":
        return (
          <ConsultaBoleto
            isLoading={isLoading}
            consultaBoleto={this.consultaBoleto}
          />
        );
      case "envia-boleto":
        return (
          <EnviaBoleto
            isLoading={isLoading}
            data={data}
            enviaBoleto={this.enviaBoleto}
          />
        );
      case "envia-erro":
        return <RelataErro relataErroManual={this.relataErroManual} />;
      case "sucesso":
        return (
          <Sucesso
            msg={msg}
            finalizar={finalizar}
            resolveEmail={this.resolveEmail}
          />
        );
      case "erro":
        return <Erro msg={"Não foi possivel encontrar os boletos..."} />;
      case "solicita-cnpj":
        return (
          <SolicitaCNPJ
            SolicitaCnpj={this.solicitaCnpj}
            isLoading={isLoading}
            closeModal={this.closeModal}
          />
        );
      case "solicita-nota-duplicata":
        return (
          <SolicitaNotaDup
            SolicitaNotaDup={this.solicitaDuplicataNota}
            isLoading={isLoading}
            closeModal={this.closeModal}
          />
        );
      case "resolvido":
        return (
          <Resolvido
            Resolvido={this.resolveEmail}
            isLoading={isLoading}
            closeModal={this.closeModal}
          />
        );
      default:
        break;
    }
  };

  render() {
    const { header, chat } = this.props;
    const { isOpen, title } = this.state;
    return (
      <ChatView>
        <Header data={header} />
        <Body chat={chat} />
        <Footer
          enviaEmailManual={this.enviaEmailManual}
          isLoading={this.state.isLoading}
        />
        <FloatingButton openModal={this.renderModal} />
        <ContainerModal
          title={title}
          isOpen={isOpen}
          closeModal={() => this.setState({ isOpen: false })}
        >
          {this.renderContent()}
        </ContainerModal>
      </ChatView>
    );
  }
}
