import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  border-bottom: 0.5px solid #484848;
`;

export const exitButton = styled.div`
  margin: 10px 10px 10px auto;
  width: 25px;
  height: 25px;
  border-radius: 100%;
`;

export const titleLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 10px;
`;
