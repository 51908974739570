import { dashboardTypes } from "../actions";

const INITIAL_STATE = {
  dashboardData: []
};

export const dashboard = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case dashboardTypes.SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.dashboard
      };
    default:
      return state;
  }
};
