import { createTypes } from "reduxsauce";

import { firebase } from "./../../utils/firebase";
import { emailsActions } from "./index";

export const userTypes = createTypes(
  `
      SET_USER
    `,
  { prefix: "USER/" }
);

const user = user => {
  return dispatch => {
    firebase
      .getEmailRealtime()
      .then(() => {})
      .catch(error => console.log("erroEmails", error));

    dispatch({
      type: userTypes.SET_USER,
      user: user
    });
  };
};

export const userActions = { user };
