import React from "react";
import Modal from "react-modal";
import { Loader } from "..";
import { icons } from "../../assets/icons/index";
import * as s from "./styled";
import { Document, Page, pdfjs } from "react-pdf";
import { Scrollbars } from "react-custom-scrollbars";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ModalContainer = props => {
  Modal.setAppElement("body");
  return (
    <Modal
      style={customStyles}
      isOpen={props.isOpen}
      onRequestClose={() => props.closeModal()}
    >
      <s.HeaderContainer>
        <s.titleLabel>{props.fileName}</s.titleLabel>
        <s.exitButton>
          <img
            id={"bton-close-modal-file-chat"}
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            src={icons.close}
            alt={"Botao que fechará a modal aberta"}
            onClick={() => props.closeModal()}
          ></img>
        </s.exitButton>
      </s.HeaderContainer>
      <Scrollbars>
        <Document
          file={{
            data: props.fileContent
          }}
          noData={"Não foi possível carregar o PDF"}
        >
          <Page
            error={"Não foi possível carregar a página"}
            loading={<Loader />}
            pageNumber={1}
            scale={1.5}
          ></Page>
        </Document>
      </Scrollbars>
    </Modal>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "940px",
    height: "calc(100% - 70px)",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflowY: "hidden"
  }
};

export default ModalContainer;
