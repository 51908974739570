import styled from "styled-components";

export const TextArea = styled.textarea`
  width: 370px;
  height: 82px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
  outline: none;
  margin-top: 11px;
  color: #484848;
  font-size: 15px;
  padding: 5px;
`;

export const SendButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 8px;
`;

export const SendButton = styled.button`
  outline: none;
  display: flex;
  justify-content: center;

  width: 80px;
  height: 30px;

  background-color: #3e64c2;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.05s linear;
  transition: 0.4s;

  ${props => props.disabled && `opacity: 0.7; cursor: not-allowed;`}
`;

export const Line = styled.div`
  height: 1px;
  border: 1px solid #484848;
  display: flex;
  flex: 1;
`;
