import styled from "styled-components";

export const SearchWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background: rgb(67, 67, 67);
  align-items: center;
  border-radius: 5px;
  padding: 7px 20px 7px 7px;
  margin: 4px 12px;
`;
export const Icon = styled.div`
  width: 20px;
  display: flex;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
`;
export const Input = styled.input`
  width: 200px;
  background: none;
  border: none;
  color: white;
  outline: none;

  ::placeholder {
    color: #ddd;
  }
`;
