import styled from "styled-components";

export const Container = styled.div`
  flex-direction: row;
  background: rgb(228, 228, 228);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  height: 7vh;
`;
export const InputWrapper = styled.div`
  display: flex;
  width: calc(100% - 140px);
  background: white;
  align-items: center;
  border-radius: 4pt;
  height: 40px;
`;

export const Input = styled.input`
  flex: 1;
  background: white;
  color: black;
  border-radius: 5px;
  padding-left: 10px;
  height: 30px;
  border: none;
  outline: none;
  ::placeholder {
    color: rgb(143, 143, 143);
  }
  ::-webkit-input-placeholder {
    font-size: 16px;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;
export const Text = styled.span`
  margin-bottom: 0pt !important;
  font-size: 12px;
`;
export const Icon = styled.img`
  margin: 0 15px;
  width: 27px;
  height: 27px;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;
export const Send = styled.img`
  margin: 0 15px;
  width: 31px;
  height: 31px;
  cursor: pointer;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;
