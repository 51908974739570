import React from "react";
import { icons } from "./../../../assets/icons";
import * as s from "./styled";

const Sucesso = props => {
  return (
    <s.Container>
      <s.Icon>
        <img src={icons.resolvido} size={30} alt={"Resolvido"} />
      </s.Icon>
      <s.Title>{props.msg}</s.Title>

      {props.finalizar && (
        <s.SendButtonContainer>
          <s.SendButton
            onClick={() => {
              props.resolveEmail(
                "Email concluído através da ação de buscar boletos na aplicação."
              );
            }}
          >
            Concluir E-mail
          </s.SendButton>
        </s.SendButtonContainer>
      )}
    </s.Container>
  );
};

export default Sucesso;
