import styled from "styled-components";

export const ChatView = styled.div`
  display: flex;
  justify-content: ${props => (props.isLoading ? "center" : "flex-start")};
  flex: 1;
  flex-direction: column;
  width: calc(100vw - 275px);
  ${({ isLoading }) => isLoading && `align-items: center;`}

  .rtf {
    margin: 0px 20px 10px 10px;
    z-index: 0;
  }
  .rtf--mb__c {
    margin: 2px;
    padding: 0px;
  }

  @media (max-width: 1024px) {
    .rtf--mb__c {
      margin: 4px;
      padding: 0px;
    }
  }

  .rtf--mb {
    width: 40px;
    height: 40px;
  }
  .rtf--ab {
    width: 40px;
    height: 40px;
  }
`;
