const INITIAL_STATE = {
  emails: [],
  loading: true
};

export const emails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "EMAILS/SET_NEW_EMAILS":
      return {
        ...state,
        emails: action.emails,
        loading: action.loading
      };
    default:
      return state;
  }
};
