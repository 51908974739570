import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { userService } from './services';
import { history } from './utils';
import { LandingPage, LoginScreen } from './screens';
import { Loader } from './components';
import { GlobalStyle } from './styles/globals.js';
import * as s from './styles/styled-app';

import { useDispatch, useSelector } from 'react-redux';
import { appActions, userActions } from './store/actions/';
const url = window.location.pathname.split('/').pop();

const App = props => {
  const app = useSelector(state => state.app);
  const user = useSelector(state => state.user);
  const email = useSelector(state => state.emails);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.initialize());
  }, []);

  const saveUser = () => {
    setLoading(true);
    setTimeout(() => {
      var user = userService.checkUser();
      if (user) {
        dispatch(userActions.user(user));
        history.push('/landingpage');
      } else {
        setError(true);
      }
      setLoading(false);
    }, 3000);
  };

  if (app.initialized) {
    if (user.user !== null) {
      return email.loading ? (
        <div style={{ width: '100vw', height: '100vh', display: 'flex' }}>
          <GlobalStyle />
          <s.Container>
            <s.Item>
              <s.Text>Carregando E-mails...</s.Text>
              <Loader size={30} />
            </s.Item>
          </s.Container>
        </div>
      ) : email.emails[0] ? (
        <div style={{ width: '100vw', height: '100vh', display: 'flex' }}>
          <GlobalStyle />

          <Switch>
            <Route path="/landingpage" render={() => <LandingPage id={url} />} />
            <Redirect to="/landingpage" />
          </Switch>
        </div>
      ) : (
        <>
          <GlobalStyle />
          <s.Container>
            <s.Item>
              <s.Text>Não foi possível trazer os E-mails...</s.Text>
            </s.Item>
          </s.Container>
        </>
      );
    } else {
      return (
        <div style={{ width: '100vw', height: '100vh', display: 'flex' }}>
          <GlobalStyle />
          <Route
            path="/login"
            render={() => <LoginScreen saveUser={saveUser} loading={loading} error={error} />}
          />
          <Redirect to="/login" />
        </div>
      );
    }
  }
  return <Loader size={80} height={'100vh'} />;
};

export default withRouter(props => <App {...props} />);
