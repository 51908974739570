import styled from 'styled-components';
import { Colors } from './../assets/theme';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Item = styled.div`
  background: ${Colors.blackSecondary};
  width: 500px;
  height: 150px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  box-shadow: 2px 1px 1px 1px ${Colors.black1};
`;

export const Text = styled.label`
  font-size: 20px;
  font-weight: bold;
`;
