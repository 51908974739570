import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const SideMenuView = styled.div`
  width: 250px;
  height: 100%;
  background: #151515;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 2px;
`;
export const ContentChatView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* border: 2px solid blue; */
`;

export const ErrorMesage = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  margin: auto;
`;
