import React, { useState, useEffect, useCallback, useRef } from "react";
import * as s from "./styled";
import { icons } from "../../../assets/icons";
import { Scrollbars } from "react-custom-scrollbars";
import { Loader } from "./../../index";
import { ModalContainer } from "../../";

const EnviaBoleto = props => {
  const [, updateState] = React.useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [isOpen, setIsOpen] = useState(false);
  const [document, setDocument] = useState("");
  const closeRef = useRef();

  const [state, setState] = useState(props.data);

  const renderView = ({ style }) => {
    const viewStyle = {
      display: "grid",
      gridTemplateColumns: "165px 165px",
      gap: "22px"
    };
    return <div style={{ ...style, ...viewStyle }} />;
  };

  const renderContent = () => {
    const closeItem = posicao => {
      var newState = null;
      newState = state;
      for (let i in state) {
        if (i == posicao) {
          newState.splice(i, 1);
          setState(newState);
          forceUpdate();
        }
      }
    };

    return state.map((boleto, i) => (
      <s.fileContainer
        key={i}
        id={"btn-file-chat"}
        onClick={e => {
          e.stopPropagation();
          setIsOpen(true);
          setDocument(boleto.data);
        }}
      >
        <s.fileTitle>
          <s.iconContainer src={icons.pdf} size={25} />
          {/* <s.titleContainer>Nome arquivo</s.titleContainer> */}
          <s.iconContainer
            src={icons.closeRed}
            size={15}
            ref={closeRef}
            onClick={e => {
              e.stopPropagation();
              closeItem(i);
            }}
          />
        </s.fileTitle>
        <div id={"bottom"}></div>
      </s.fileContainer>
    ));
  };

  return props.isLoading ? (
    <s.LoaderContainer>
      <Loader size={45} />
    </s.LoaderContainer>
  ) : (
    <>
      {isOpen && (
        <ModalContainer
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          fileContent={document}
        />
      )}
      <s.Container>
        <Scrollbars renderView={renderView}>{renderContent()}</Scrollbars>
        <s.SendButtonContainer>
          <s.SendButton
            onClick={() => {
              props.enviaBoleto(state);
            }}
          >
            Enviar
          </s.SendButton>
        </s.SendButtonContainer>
      </s.Container>
    </>
  );
};
export default EnviaBoleto;
