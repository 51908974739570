import React, { Component } from "react";
import * as s from "./style.header";
import { icons } from "./../../assets/icons";

export default class Header extends Component {
  renderHeader = data => {
    return (
      <s.Container>
        <s.IconeContainer>
          <s.Icone src={icons.person} />
        </s.IconeContainer>
        <s.TextContainer>
          <s.TextItem>
            <s.Nome>{data.remetente_nome}</s.Nome>
            <s.Email>{`<${data.remetente_email}>`}</s.Email>
          </s.TextItem>
          <s.Assunto title={data.assunto}>{data.assunto}</s.Assunto>
        </s.TextContainer>
      </s.Container>
    );
  };

  render() {
    const { data } = this.props;
    return this.renderHeader(data);
  }
}
