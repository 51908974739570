import styled from "styled-components";

export const SendButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 8px;
`;

export const SendButton = styled.button`
  outline: none;
  display: flex;
  justify-content: center;
  width: 80px;
  height: 30px;
  background-color: #3e64c2;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.05s linear;
  transition: 0.4s;
  margin-bottom: 10px;
  ${props => props.disabled && `opacity: 0.7; cursor: not-allowed;`}
`;

export const CancelButton = styled.button`
  outline: none;
  display: flex;
  justify-content: center;
  width: 80px;
  height: 30px;
  background-color: #c23520;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.05s linear;
  transition: 0.4s;
  margin-bottom: 10px;
  ${props => props.disabled && `opacity: 0.7; cursor: not-allowed;`}
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #000;
`;
