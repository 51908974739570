import React from "react";
import Loader from "react-loader-spinner";
import { Colors } from "../../assets/theme";

const DefaultLoader = props => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: props.height || "calc(100% - 140px)",
        margin: props.margin
      }}
    >
      <Loader
        width={props.size}
        height={props.size}
        color={props.color || Colors.whiteSecondary}
        type="TailSpin"
      />
    </div>
  );
};

export default DefaultLoader;
