import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 12px;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0px;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(228, 228, 228);
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const SendButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
`;

export const SendButton = styled.button`
  outline: none;
  display: flex;
  justify-content: center;
  width: 120px;
  height: 30px;
  background-color: #3e64c2;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.05s linear;
  transition: 0.4s;
  margin-top: 7px;
  ${props => props.disabled && `opacity: 0.7; cursor: not-allowed;`}
`;
