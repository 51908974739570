import { subscribeTypes } from "../actions/subscribe-action";

const INITIAL_STATE = {
  subscribe: {}
};

export const subscribe = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case subscribeTypes.SET_SUBSCRIBE:
      return {
        ...state,
        subscribe: action.subscribe
      };
    default:
      return state;
  }
};
