import React, { useState } from "react";
import * as Login from "./styled-login";
import { userService } from "../../services";
import { Loader } from "../../components";
import { Colors } from "./../../assets/theme";

const LoginScreen = props => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (email, password) => {
    userService
      .login({
        email: email,
        password: password
      })
      .then(() => props.saveUser());
  };
  const enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === 13 && email !== "" && password !== "") {
      handleLogin(email, password);
    }
  };
  return (
    <Login.Container onKeyPress={e => enterPressed(e)}>
      <Login.Wrapper>
        <Login.Title>Faça login no Robomail</Login.Title>
        <Login.LabelWrapper>
          <Login.Input
            placeholder="user@email.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Login.LabelWrapper>
        <Login.LabelWrapper>
          <Login.Input
            placeholder="*************"
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </Login.LabelWrapper>
        <Login.SubmitButton
          onClick={() => handleLogin(email, password)}
          disabled={email === "" || password === ""}
        >
          {props.loading && (
            <Loader
              size={20}
              style={{ marginRight: "10px" }}
              color={Colors.grayTab}
            />
          )}
          Entrar
        </Login.SubmitButton>
      </Login.Wrapper>
      <Login.Error>
        {props.error &&
          "Não foi possível realizar seu login, verifique seu email e senha!"}
      </Login.Error>
    </Login.Container>
  );
};

export default LoginScreen;
