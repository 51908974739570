import styled from "styled-components";

export const ButtonStyle = styled.button`
  outline: none;
  display: flex;
  border: 1px solid ${props => (props.selected ? "#adadad" : "#0c0c0c")};
  background-color: ${props => (props.selected ? "#e6e6e6" : "#0c0c0c")};
  color: ${props => (props.selected ? "#0c0c0c" : "#e6e6e6")};
  width: 80px;
  height: 30px;
  /* padding: 6px 12px; */
  border-radius: 4px;
  margin: 5px 2px 2px 2px;
  transition: background-color 0.05s linear;
  justify-content: center;
  transition: 0.4s;

  :hover {
    color: #0c0c0c;
    background-color: #e6e6e6;
    border: 1px solid #adadad;
  }
`;
export const Text = styled.span`
  margin-bottom: 0pt !important;
  font-size: 12px;
`;
